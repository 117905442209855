<template>
  <div style="margin-top: 50px">
    <h1 class="h1">404</h1>
    <p class="p">抱歉您当前的页面还在异次元 | <span @click="rentuMain">返回首页</span></p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  watch: {},
  methods: {
    rentuMain() {
      this.$router.push({ path: "/main" });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.h1 {
  text-align: center;
}
.p {
  margin-top: 2rem;
  font-size: 0.8rem;
  text-align: center;
  span {
    color: rgb(83, 83, 243);
  }
}
</style>